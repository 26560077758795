@import '../../Core/scss/t.scss';
@import '../../Core/scss/u.scss';

.DashboardQuickReference {
	border-left: 1px solid rgba(255, 255, 255, 0.15);
	height: 100%;

	&__Header {
		padding-inline: 35px;
		height: 110px;
		width: 100%;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-shadow: 6px 2px 12px 0 rgb(0 0 0 / 50%);
	}

	&__Close {
		color: #009de0;
		transition: color $t-hover;

		& > .Icon {
			cursor: pointer;
		}

		&:hover {
			color: mix(#009de0, #fff, 50%);
		}
	}

	&__Body {
		@include scrollbar;
		padding: 35px;
		color: #fff;
		overflow-y: auto;
		height: calc(100vh - 110px);

		& h1 {
			font-size: 16px;
			letter-spacing: -1.07px;
			font-weight: 700;
			margin-bottom: 0.5rem;
		}

		& p {
			margin-bottom: 2rem;
			font-size: 14px;
			letter-spacing: -0.93px;
			line-height: 22px;
			font-weight: 400;
			color: rgb(255, 255, 255, 0.85);
		}
	}
}
