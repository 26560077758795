.MultilineChartTooltip {
	background-color: #002838;
	color: #fff;
	border: none;
	outline: none;
	font-size: 11px;
	min-width: 100px;
	padding-bottom: 0.4em;

	&__Title {
		font-weight: 700;
		padding-block: 0.4em;
		padding-inline: 10px;
		background-color: #002838;
	}

	&__Content {
		display: flex;
		flex-direction: column;
		gap: 1px;
		background-color: transparentize(#fff, 0.75);
	}

	&__Item {
		display: flex;
		align-items: center;
		padding-inline: 10px;
		background-color: #002838;
		padding-block: 0.4em;
		gap: 5px;
	}

	&__Label {
		min-width: 18ch;
	}

	&__Value {
		justify-self: flex-end;
		margin-left: auto;
	}
}
