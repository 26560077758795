$brand-color: #009de0;

.ToggleButton {
	height: 16px;
	width: 53px;
	border: 1px solid rgba(0, 36, 51, 0.4);
	border-radius: 8px;
	padding: 0px;
	padding-inline: 4px;
	cursor: pointer;
	background-color: #fff;

	&:focus-visible {
		outline: none;
	}

	&--Disabled {
		opacity: 0.8;
		cursor: not-allowed;
	}

	&--On {
		border-color: $brand-color;
	}

	&__Tick {
		background-color: rgba(0, 36, 51, 0.4);
		transition: transform 150ms ease-out;
		height: 8px;
		width: 22px;
		border-radius: 4px;
		transform: translateX(0px);
		margin: 0;

		&--On {
			background-color: $brand-color;
			transform: translateX(20px);
		}
	}
}
