.Modal {
	height: calc(100% - 30px);
	max-height: 500px;
	background-color: #fff;
	border-radius: 20px;
	box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
	display: flex;
	flex-flow: column;

	&__Header,
	&__Footer {
		height: 80px;
		border-style: solid;
		border-color: #d4e0e6;
		flex: none;
		padding-left: 40px;
		padding-right: 40px;
		display: flex;
		align-items: center;
	}

	&__Header {
		border-bottom-width: 1px;
	}

	&__Footer {
		border-top-width: 1px;
		justify-content: flex-end;
		grid-gap: 10px;

		.Button {
			width: 114px;
		}
	}

	&__Body {
		flex: auto;
		overflow: auto;
	}

	&__Title {
		color: #429dda;
		font-size: 20px;
		font-weight: 400;

		strong {
			font-weight: 500;
		}
	}
}

.ModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}
