.Calendar {
	&__DateValue {
		font-size: 13px;
	}

	&__InputFields {
		cursor: pointer;
		border: 1px solid rgba(255, 255, 255, 0.25);
		background-color: #002433;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-around;
		gap: 4px;
		border-radius: 15px;
		height: 30px;
		padding: 8px 28px 8px 17px;
		font-size: 13px;
		transition: border-color 150ms ease-out, width 150ms ease-out;
		width: 100%;

		&:hover {
			border-color: #fff;
		}

		&--Active {
			border-color: #fff;
		}
	}

	&__Input {
		position: relative;

		& > div:not(.Calendar__Icon, .Calendar__InputFields) {
			position: absolute;
			z-index: 10;
			top: 30px;
			right: -320px;
			pointer-events: none; // Fix bug that prevents outside click in this box
		}

		& > div:not(.Calendar__Icon, .Calendar__InputFields) > * {
			pointer-events: all; // Re-enable outside-click check for children
		}
	}

	&__InputElement {
		width: fit-content;
		max-width: 13ch;
		color: #fff;
		background-color: transparent;
		box-sizing: border-box;
		font-family: inherit;
		cursor: pointer;
		outline: none;
		border: none;
		text-align: center;

		&:focus-within {
			outline: none;
			border-color: rgba(255, 255, 255, 0.6);
		}
	}

	&__Icon {
		position: absolute;
		right: 10px;
		top: 15px;
		transform: translateY(-50%) translateX(0px) rotate(0deg);
		transform-origin: center;

		&--Open {
			transform: translateY(-65%) translateX(-2px) rotate(180deg);
		}
	}
}
