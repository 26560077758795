@import '../Core/scss/t.scss';

.RIAMS {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&__Menu {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		display: flex;
		z-index: 1;
	}

	&__Main {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		height: auto;
		flex: 1 0 auto;
	}

	&__RambollLogo {
		position: absolute;
		top: 45px;
		right: 45px;
		transition: color $t-hover;
	}

	&__Model {
		position: relative;
	}
}

.RIAMS:not(.has-sidemenuOverlay) {
	.RIAMS__Main {
		left: 110px;
	}

	.RIAMS__RambollLogo {
		color: #00a0dd;
	}
}

.RIAMS.has-sidemenuOverlay {
	.RIAMS__Main {
		left: 0;
	}

	.RIAMS__RambollLogo {
		color: #fff;
	}
}
