@import '../../Core/scss/u.scss';

.Dashboard {
	inset: 0;
	display: grid;
	grid-template-columns: 1fr 0px;
	overflow: hidden;

	&--Fullscreen {
		grid-template-columns: 1fr 0px;
		z-index: 1;
	}

	&__SiteTitle {
		position: absolute;
		top: 35px;
		left: 120px;
		z-index: 1;
		pointer-events: none;
	}

	&__ShowQuickReference {
		grid-template-columns: 1fr minmax(0px, 300px);

		& > .Dashboard__QuickAccess {
			width: 100%;
		}
	}
	&__ShowQuickReference.Dashboard--Fullscreen {
		grid-template-columns: minmax(700px, 1fr) minmax(0px, 300px);
	}

	&__Content {
		background-color: #002433;
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: grid;
		grid-template-rows: 110px 62px minmax(0px, 1fr);
		border-left: 1px solid rgba(255, 255, 255, 0.25);
		position: relative;
	}

	&__Navigation {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 46px;
		padding-right: 24px;
		padding-block: 16px;
	}

	&__Model {
		user-select: none; // Disable text selection which bugs out on double click (selecting the headline instead of interacting with the model)
	}

	&__Breadcrumb {
		color: #fff;
	}

	&__Charts {
		@include scrollbar;
		display: grid;
		grid-template-columns: minmax(0px, 1fr) minmax(0px, 1fr);
		grid-auto-rows: max-content;
		gap: 6px;
		width: 100%;
		padding-inline: 24px;
		padding-bottom: 24px;
		overflow-y: auto;
		position: relative;
		height: calc(100vh - 62px - 110px);

		&--Fullscreen {
			grid-template-columns: minmax(0px, 1fr) minmax(0px, 1fr);
		}
	}

	&__Metric {
		@include scrollbar;

		display: grid;
		grid-template-columns: minmax(0px, 1fr);
		grid-auto-rows: max-content;
		gap: 6px;
		padding-inline: 24px;
		// Padding bottom gives us a bit of room at the bottom, if we have to scroll
		padding-bottom: 24px;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		position: relative;
		height: calc(100vh - 62px - 110px);

		&--Fullscreen {
			grid-template-columns: minmax(0px, 1fr) minmax(0px, 1fr);

			& > .DashboardCard:first-child {
				grid-column: 1 / 3;
			}
		}
	}

	&__QuickAccess {
		width: 0px;
		overflow: hidden;
		background-color: #002433;
	}

	&__Loading {
		position: absolute;
		inset: 0;
		height: 75%;
		width: 100%;
		z-index: 5;
	}
}
