.AnalysisExplorer {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	&__ResultsTable {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		padding-left: 24px;
	}
}
