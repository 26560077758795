@import '~react-toastify/dist/ReactToastify.min.css';

.App {
	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	&,
	input,
	select {
		font-family: 'Gotham Rounded A', 'Gotham Rounded B', Arial, Helvetica,
			sans-serif;
		letter-spacing: calc(-0.14em / 14);
		font-weight: 500;
	}
}

/** Additions to the toasts seen in admin area */
.Toast {
	color: #fff;

	&__Progress {
		background-color: #fff;
		&--Error {
		}
		&--Success {
		}
	}

	&--Error {
		background-color: #e00039;
	}

	&--Success {
		background-color: #009de0;
	}
}
