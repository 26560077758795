@import '../../Core/scss/t.scss';

$backgroundColor: #002433;

.ExplorerNavigation {
	display: flex;

	&__Button {
		background-color: $backgroundColor;
		transition: background-color $t-hover, color $t-hover;
		color: transparentize(#fff, 0.5);

		&:hover {
			background-color: mix($backgroundColor, #fff, 90%);
			color: transparentize(#fff, 0.2);
		}
	}

	&__Button:first-child {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	&__Button:last-child {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&__Button + &__Button {
		border-left: 1px solid #264451;
	}
}
