@use 'sass:math';

@import '../../scss/t.scss';
@import '../../scss/f.scss';

.Button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	line-height: 1;
	outline: none;
	border: none;
	background-color: transparent;
	position: relative;

	&__Inner {
		margin-top: -1px; // Optical alignment
	}
}

.Button--Height-Default {
	$fontSize: 14px;
	$padding: 15px;
	$height: $inputHeightDefault;

	height: $height;
	border-radius: math.div($height, 2);
	padding-left: $padding;
	padding-right: $padding;
	font-size: $fontSize;
}

.Button--Height-Small {
	$fontSize: 13px;
	$padding: 10px;
	$height: $inputHeightSmall;

	height: $height;
	border-radius: math.div($height, 2);
	padding-left: $padding;
	padding-right: $padding;
	font-size: $fontSize;
}

.Button--Height-Large {
	$fontSize: 13px;
	$padding: 10px;
	$height: $inputHeightLarge;

	height: $height;
	border-radius: math.div($height, 2);
	padding-left: $padding;
	padding-right: $padding;
	font-size: $fontSize;
}

.Button--Theme-Blue {
	$bgc: #009de0;

	background-color: $bgc;
	color: #fff;
	transition: background-color $t-hover;

	&:hover {
		background-color: mix($bgc, #ffffff, 80%);
	}
}

.Button--Theme-Grey {
	color: transparentize(#fff, 0.4);
	transition: color $t-hover;

	&:hover {
		color: transparentize(#fff, 0.1);
	}
}

.Button--Theme-OutlineGrey {
	color: transparentize(#fff, 0.4);
	transition: color $t-hover;
	border: 1px solid currentColor;

	&:hover {
		color: transparentize(#fff, 0.1);
	}
}

.Button--Theme-OutlineBlue {
	color: #009de0;
	transition: color $t-hover, background-color $t-hover;
	border: 1px solid currentColor;

	&:hover {
		background-color: transparentize(#009de0, 0.9);
	}
}

.Button--Theme-TrafficLight {
	color: #fff;
	transition: color $t-hover, background-color $t-hover;
	border: 1px solid currentColor;
	height: 30px;
	border-radius: 15px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 13px;

	&:disabled {
		cursor: initial;
	}

	&[data-active='false'] {
		color: rgba(255, 255, 255, 0.25);

		&:hover {
			color: rgba(255, 255, 255, 0.8);
			border-color: currentColor;
		}
	}

	& > .Button__Inner {
		color: #fff;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.Button--Theme-TrafficLightRound {
	color: #fff;
	transition: color $t-hover, background-color $t-hover;
	border: 1px solid currentColor;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	padding-left: 5px;
	padding-right: 5px;
	font-size: 13px;

	&:disabled {
		cursor: initial;
	}

	&[data-active='false'] {
		color: rgba(255, 255, 255, 0.25);

		&:hover {
			color: rgba(255, 255, 255, 0.8);
			border-color: currentColor;
		}
	}

	& > .Button__Inner {
		color: #fff;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.Button--Theme-Circle {
	color: rgba(255, 255, 255, 0.25);
	transition: color $t-hover, background-color $t-hover;
	border: 1px solid currentColor;
	height: 30px;
	width: 30px;
	border-radius: 50%;

	&:hover {
		color: rgba(255, 255, 255, 0.8);
	}
}
