.AdminAccounts {
	display: grid;
	grid-template-rows: 196px 1fr;

	&__Header {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-bottom: 24px;

		&Top {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__Logo {
		color: #009de0;
	}

	&__Search {
		max-width: 260px;
	}

	&__Title {
		font-size: 2rem;
		color: #002433;

		&Bar {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
	}
}
