.List {
	display: flex;
	flex-direction: column;
	gap: 1px;
	border: 1px solid #d4e0e6;
	background-color: #d4e0e6;
	border-radius: 10px;
	overflow: hidden;

	&__Headline {
		font-size: 16px;
		font-weight: 500;
		padding-bottom: 12px;
	}

	&__Top {
		display: flex;
		align-items: center;
		width: 100%;

		& > .Button {
			position: absolute;
			top: -52px;
			right: 0;
		}
	}
}
