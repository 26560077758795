@import '../../Core/scss/u.scss';

.AdminLayout {
	padding: 35px 40px;
	overflow: auto;
	height: 100%;
	display: grid;
	grid-template-rows: 196px 1fr;
	@include scrollbar;

	&__Header {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-bottom: 24px;

		&Top {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			min-height: 40px;
		}
	}

	&__Logo {
		color: #009de0;
	}

	&__Search {
		max-width: 260px;
	}

	&__Title {
		font-size: 1.8rem;

		&Bar {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
			width: 100%;
		}
	}

	&__Content {
		position: relative;
	}
}
