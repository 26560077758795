@import '../../scss/t.scss';

.IconButton {
	display: flex;
	align-items: center;
	cursor: pointer;
	column-gap: 4px;

	&__Text {
		font-size: 13px;
		margin-top: -1px; // Optical alignment
	}

	&__Text,
	&__Icon {
		transition: color $t-hover;
	}
}

.IconButton--align {
	&-right {
		flex-direction: row;
	}

	&-left {
		flex-direction: row-reverse;
	}
}

.IconButton--theme {
	&-blue-grey {
		.IconButton__Text {
			color: transparentize(#fff, 0.4);
		}
		.IconButton__Icon {
			color: #00a0dd;
		}
	}

	&-none {
		color: red; // Fallback color

		.IconButton__Text,
		.IconButton__Icon {
			color: currentColor;
		}
	}
}
