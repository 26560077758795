.Configurator {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #002432;
	display: flex;
	flex-flow: column;
	color: #fff;
	z-index: 2;
	border-left: 1px solid rgba(255, 255, 255, 0.25);

	.ConfiguratorHeader {
		flex-grow: 0;
	}

	.ConfiguratorBody {
		flex-grow: 1;
		overflow: auto;
	}
}
